<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container container-small">
        <header class="admin-header">
          <h1>Benutzer</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div class="content-wrap">
            <header class="content-header">
              <h3>{{ playlist.name }}</h3>
            </header>
            <div class="content-body">
              <ul style="margin: 0; padding: 0">
                <li v-for="playlist_ad in playlist_ads" v-bind:key="playlist_ad.uuid" style="margin: 0 0 10px 0; padding: 0; list-style-type: none">
                  Status: {{ playlist_ad.status }} / Name:
                  {{ playlist_ad.name }}
                  <span v-if="playlist_ad.thumbnail">
                    /
                    <a :href="playlist_ad.thumbnail" target="_blank" style="color: #444; font-weight: 600">Thumbnail aufrufen ›</a></span
                  >
                  <span v-if="playlist_ad.asset_file">
                    /
                    <a :href="playlist_ad.asset_file" target="_blank" style="color: #444; font-weight: 600">Bild/Video aufrufen ›</a></span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "schedules",
  components: {
    AdminNav,
  },
  data() {
    return {
      playlist: {},
      playlist_ads: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/playlists/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.playlist = response.data.playlist;
          this.get_playlist_advertisments(1);
        });
    },
    get_playlist_advertisments(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/playlists/" + this.$route.params.uuid + "/advertisments?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          response.data.advertisments.forEach((ad) => {
            this.playlist_ads.push(ad);
          });
          if (response.data.meta.pagination.links.next) {
            this.get_playlist_advertisments(response.data.meta.pagination.links.next.split("=")[1]);
          } else {
            this.loading = false;
          }
        });
    },
    /*update_user: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid, this.user, { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'notification',
          type: 'success',
          text: "Erfolgreich gespeichert."
        });
        this.get_data();
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    },*/
  },
  mounted() {
    this.get_data();
  },
};
</script>
